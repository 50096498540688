<template>
	<div class="page">
		<gc-header header-data="vendorManage">
			<template #ops>
				<el-button v-has="'monitor:device:manufacturer:maintain'" type="primary" @click="handleFactory()">
					新增厂商
				</el-button>
			</template>
		</gc-header>
		<div class="page-main">
			<gc-custom-search key-word="厂商名称" :search.sync="searchKey" @keyup.enter.native="currentPageChange(1)">
				<template #icon>
					<i class="el-icon-search pointer" @click="currentPageChange(1)"></i>
				</template>
			</gc-custom-search>
			<gc-table
				class="table"
				:columns="columns"
				:table-data="tableData"
				:border="true"
				:show-page="true"
				:current-page="pageInfo.current"
				:total="pageInfo.total"
				:page-size="pageInfo.size"
				@size-change="handleSizeChange"
				@current-page-change="currentPageChange"
			></gc-table>
		</div>
		<!-- 新增修改厂商弹框 -->
		<factory-dialog :show.sync="visible" :detail="detail" @update="updateList" />
	</div>
</template>

<script>
import FactoryDialog from './FactoryDialog.vue'
import { apiManufacturerList } from '@/apis/factory.api'

export default {
	name: 'factoryManage',
	components: { FactoryDialog },
	data() {
		return {
			baseColumns: [
				{ key: 'manufacturerCode', name: '厂商编号' },
				{ key: 'manufacturerName', name: '厂商名称' },
				{ key: 'contactPhone', name: '联系电话' },
				{ key: 'contactName', name: '联系人' },
				{ key: 'manufacturerAddress', name: '厂商地址' },
			],
			tableData: [],
			pageInfo: {
				current: 1,
				size: 20,
				total: 0,
			},
			searchKey: '',
			visible: false,
			detail: null,
		}
	},
	computed: {
		columns() {
			return this.$has('monitor:device:manufacturer:maintain')
				? [
						...this.baseColumns,
						{
							key: 'ops',
							name: '操作',
							render: (h, row) => {
								return this.$has('monitor:device:manufacturer:maintain') ? (
									<span class="ops-btn" onClick={() => this.handleFactory(row)}>
										修改
									</span>
								) : (
									''
								)
							},
						},
				  ]
				: this.baseColumns
		},
	},
	mounted() {
		this.getManufacturerList()
	},
	methods: {
		handleSizeChange(size) {
			this.pageInfo.size = size
			this.pageInfo.current = 1
			this.getManufacturerList()
		},
		getManufacturerList() {
			const { current = 1, size = 20 } = this.pageInfo
			const params = {
				current,
				size,
				manufacturerName: this.searchKey.trim(),
			}
			apiManufacturerList(params)
				.then(({ records = [], ...pageInfo }) => {
					this.tableData = records
					this.pageInfo = {
						...this.pageInfo,
						...pageInfo,
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		currentPageChange(current) {
			this.pageInfo.current = current
			this.getManufacturerList()
		},
		// 新增、修改
		handleFactory(row) {
			this.detail = row || null
			this.visible = true
		},
		// 新增、修改后更新列表
		updateList(value) {
			// 新增时重置搜索条件
			if (!value) {
				this.pageInfo.current = 1
				this.searchKey = ''
			}
			this.visible = false
			this.getManufacturerList()
		},
		// 作废
		handleDelete() {
			this.$confirm('你确定要作废该厂商吗？作废后将不可撤销。')
				.then(() => {
					console.log('确定')
				})
				.catch(() => {
					console.log('取消')
				})
		},
	},
}
</script>
<style lang="scss" scoped>
.header {
	@include base-button($width: 120px);
}
.page-main {
	flex-direction: column;
	padding: 20px 24px;
}
.table {
	margin-top: 22px;
}
::v-deep .ops-btn {
	cursor: pointer;
	color: $base-color-theme;
	+ .ops-btn {
		margin-left: 22px;
		color: #ff6a4d;
	}
}
</style>
